// extracted by mini-css-extract-plugin
export var descriptionText = "people-presentation-module--description-text--93154";
export var grid = "people-presentation-module--grid--5ce0e";
export var image = "people-presentation-module--image--30b05";
export var item = "people-presentation-module--item--2a495";
export var list = "people-presentation-module--list--d14df";
export var nameText = "people-presentation-module--nameText--c057d";
export var overwrite = "people-presentation-module--overwrite--85c59";
export var sectionContainer = "people-presentation-module--section-container--ed299";
export var specText = "people-presentation-module--specText--175f2";
export var subtitleText = "people-presentation-module--subtitle-text--8bb2e";
export var titleText = "people-presentation-module--title-text--4a1d9";