import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    overwrite,
    sectionContainer,
    grid,
    titleText,
    subtitleText,
    descriptionText,
    list,
    item,
    image,
    nameText,
    specText,
} from './people-presentation.module.scss';

import { ISection } from '../../models/section.model';
import { IPerson } from '../../models/person.model';

import Section from '../hoc/section';

interface IPeopleSection extends ISection {
    items: {
        people: IPerson[];
    };
}

interface IPresentationProps {
    className?: string;
    section: IPeopleSection;
    TitleTag?: React.ElementType;
}

const PeoplePresentation: React.FC<IPresentationProps> = ({
    className = '',
    TitleTag,
    section,
}) => {
    const {
        sectionId,
        css,
        style,
        items: { people },
    } = section;

    const [title, subtitle, description] = section.content.texts;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionContainer} ${overwrite}`}
            TitleTag={TitleTag}
            title={title}
            subtitle={subtitle}
            description={description}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: descriptionText,
            }}
            css={css}
            style={style}
        >
            <ul className={list}>
                {people.map((person: IPerson, index) => {
                    return (
                        <li className={item} key={`person-${index}`}>
                            <Image className={image} media={person.media} />
                            <p className={nameText}>
                                {person.title && person.title + ' '}
                                {person.firstName} {person.lastName}
                            </p>
                            <p className={specText}>{person.position}</p>
                        </li>
                    );
                })}
            </ul>
        </Section>
    );
};

export default PeoplePresentation;
